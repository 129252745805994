import { axiosDefault, axiosWithAuth } from "../auth/service/axios.config";

// avatar methods
export async function getAllAvatarByUser() {
  try {
    const res = await axiosWithAuth.get("/api/avatar/byUser");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function uploadAvatar(avatarData) {
  try {
    const formData = new FormData();
    formData.append("file", avatarData);

    const res = await axiosWithAuth.post("/api/avatar/add", formData, {
      headers: {
        ...axiosWithAuth.defaults.headers,
        "Content-Type": "multipart/form-data",
      },
    });
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    console.log({ message: error.response.data.message });
    return { message: error.response.data.message };
  }
}

// language methods
export async function getAllLanguage() {
  try {
    const res = await axiosWithAuth.get("/api/language");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function createLanguage() {
  try {
    const res = await axiosWithAuth.post("/api/language/add");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function updateLanguage(languageId, languageData) {
  try {
    const res = await axiosWithAuth.put(`/api/language/${languageId}`, JSON.stringify(languageData));
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function getLanguageById(languageId) {
  try {
    const res = await axiosWithAuth.get(`/api/language/${languageId}`);
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

// voice methods
export async function getAllVoice() {
  try {
    const res = await axiosWithAuth.get("/api/voice");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function createVoice() {
  try {
    const res = await axiosWithAuth.post("/api/voice/add");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function updateVoice(voiceId, voiceData) {
  try {
    const res = await axiosWithAuth.put(`/api/language/${voiceId}`, JSON.stringify(voiceData));
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function getVoiceById(voiceId) {
  try {
    const res = await axiosWithAuth.get(`/api/voice/${voiceId}`);
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

// synthetic methods
export async function getAllSyntheticByAuth() {
  try {
    const res = await axiosWithAuth.get("/api/v1/synthetic/byAuth");
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function addSynthetic(syntheticData, provider) {
  try {
    const res = await axiosWithAuth.post(`/api/v1/synthetic/add/${provider}`, JSON.stringify(syntheticData));
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

// other methods
export async function getConfigData(configDataId) {
  try {
    const res = await axiosWithAuth.get(`/api/configData/${configDataId}`);
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function getTaskById(taskId) {
  try {
    const res = await axiosWithAuth.get(`/api/tasks/${taskId}`);
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function updateTaskByGuid(taskGuid) {
  try {
    const res = await axiosWithAuth.get(`/api/tasks/updateByGuid/${taskGuid}`);
    return res.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
}

export async function deleteSynthetic(syntheticGuid) {
  try {
    const res = await axiosWithAuth.get(`/api/v1/synthetic/hide/${syntheticGuid}`);
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function getAllSharedAvatars() {
  try {
    const res = await axiosWithAuth.get("/api/avatar/getShared");
    if (res.status / 100 !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}

export async function uploadSharedAvatar(avatarData) {
  try {
    const formData = new FormData();
    formData.append("file", avatarData);

    const res = await axiosWithAuth.post("/api/avatar/addShared", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (Math.floor(res.status / 100) !== 2) {
      throw res.data;
    }
    return res.data;
  } catch (error) {
    return { message: error.response.data.message };
  }
}
